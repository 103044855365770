import React from "react";
import theme from "theme";
import { Theme, Text, Box, Hr, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				CORESTRENGTH HUB
			</title>
			<meta name={"description"} content={"Ми більше, ніж тренажерний зал – ми спільнота, яка зосереджена на тому, щоб допомогти вам розкрити свій потенціал і досягти здоров’я на всіх рівнях."} />
			<meta property={"og:title"} content={"CORESTRENGTH HUB"} />
			<meta property={"og:description"} content={"Ми більше, ніж тренажерний зал – ми спільнота, яка зосереджена на тому, щоб допомогти вам розкрити свій потенціал і досягти здоров’я на всіх рівнях."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-2.jpg?v=2024-05-31T12:21:21.980Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text1" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text" />
			<Override slot="link1" />
		</Components.Header>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="50%"
				padding="0px 40px 0px 15px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 60px 0px"
			>
				<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline1" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Наші послуги
				</Text>
				<Text margin="0px 0px 1rem 0px" color="--lightD2" font="--lead">
					Основою CoreStrength Hub є наша команда досвідчених тренерів. Кожен тренер сертифікований і має глибоке розуміння як основ фітнесу, так і новітніх методик тренувань. Вони не просто інструктори – вони ваші особисті прихильники фітнесу, які супроводжують вас протягом кожного тренування.
				</Text>
				<Text margin="auto 0px 0px 0px" color="--lightD2" font="--lead">
					У цьому огляді лише поверхня того, що може запропонувати CoreStrength Hub. Ми запрошуємо вас відвідати нас і переконатися на власні очі, як наші послуги можуть змінити вашу фітнес-подорож. Наші співробітники готові обговорити ваші цілі та те, як ми можемо допомогти в їх досягненні. Пам’ятайте, що це лише проблиск того, що чекає на вас у CoreStrength Hub.
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="50%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Комплексні рішення для фітнесу
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Індивідуальне навчання: Спеціальні плани тренувань, розроблені відповідно до ваших унікальних цілей і фізичних вимог, зосереджуючись на ефективності та безпеці.
 Індивідуальні заняття:{" "}
							<br />
							<br />
							Інтенсивний, персоналізований фокус на ваших фітнес-цілях.{"\n"}
							<br />
							<br />
							{" "}Навчання для партнерів: поділіться своїм сеансом з другом або партнером для мотивації та підтримки.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Групові фітнес-заняття
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Групові фітнес-заняття:  різноманітні заняття, які відповідають різним інтересам і рівням фізичної підготовки, і все це в рамках мотивуючої групи.
							<br />
							<br />
							{"\n "}Йога та пілатес: покращує гнучкість, рівновагу та силу ядра.{"\n"}
							<br />
							<br />
							{" "}Інтервальне тренування високої інтенсивності (HIIT): Покращуйте серцево-судинну форму та спалюйте калорії.{"\n"}
							<br />
							<br />
							{" "}Класи силових тренувань: Нарощуйте м’язи та збільшуйте силу за допомогою найсучаснішого обладнання.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Програми здоров’я та оздоровлення:
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Програми здоров’я та оздоровлення: Програми, розроблені для покращення не лише фізичного, а й психічного благополуччя.
							<br />
							<br />
							{"\n "}Консультації з питань харчування:{" "}
							<br />
							Експертні поради щодо того, як правильно живити своє тіло, щоб доповнити свій розпорядок фітнесу.{"\n"}
							<br />
							<br />
							{" "}Велнес-семінари: Сесії, присвячені психічному здоров’ю, зниженню стресу та розвитку позитивного мислення.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px" />
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px" />
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer>
			<Override slot="link3">
				0977186587
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6620d5f56bbd20002369dde3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});